/* Lists */

ul,
ol,
dl {
  padding-left: 1rem;
  font-size: $default-font-size;
  li {
    line-height: 1.8;
  }
}

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0;
  li {
    padding-left: 1.5rem;
    &:before {
      font-family: "themify";
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem;
      font-size: .6rem;
    }
  }
}

.list-ticked {
  li {
    &:before {
      content: '\e64c';
      color: $danger;
    }
  }
}

.list-arrow {
  li {
    &:before {
      content: '\e649';
      color: $success;
    }
  }
}

.list-star {
  li {
    &:before {
      content: '\e60a';
      color: $warning;
    }
  }
}